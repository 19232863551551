<template>
    <div>
        <div class="class-body">
            <div :class="{
                'class-card':true,
                's-card':selectClass == item.key_name
            }" v-for="item in classList" :key="item.category" @click="getClassName(item.key_name)">
                    {{item.key_name}}
            </div>
        </div>
        <adContent></adContent>
        <div class="list-title">{{gameList.title}}</div>
        <div class="game-list">
            <div :class="{
                'game-card':true,
                'm-padding':isMobile,
                'w-padding':!isMobile
            }" v-for="item in gameList.games" :key="item.id" @click="goDetails(item.id)">
                <div class="card-body"  v-if="!isMobile">
                    <div class="card-l">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="card-r">
                        <div class="r-title line-clamp">{{item.title}}</div>
                        <div class="r-syn line-clamp">{{item.synopsis}}</div>
                    </div>
                </div>
                <div class="m-body" v-else>
                    <img :src="item.logo" alt="">
                    <p v-if="isMobile" class="m-title line-clamp">{{item.title}}</p>
                    <p v-if="isMobile" class="m-syn line-clamp">{{item.synopsis}}</p>
                </div>
                <div class="card-play">
                    PLAY
                </div>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import navMenu from '@/utils/navMenu'
import { isMobile } from "@/utils/api";
import { searchGame, getClassList, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components:{
            adContent
        },
        data() {
            return {
                gameList: {},
                classList: [],
                selectClass: '',
                isMobile:false,
                blurbUrl: process.env.VUE_APP_BASE_GAME,
            }
        },
        mounted() {
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
            this.isMobile = isMobile();
        },
        methods: {
            async searchGame(name){
                let res = await searchGame({q:name});
                this.gameList = {
                    title: name,
                    games: res
                };
            },
            async getList(){
                let res = await getGame();
                this.gameList = {
                    title: 'GAME LIST',
                    games: res
                };
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.url
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(category){
                this.selectClass = category;
                // let res = await getGameByKey({category});
                // this.gameList = {
                //     title: category,
                //     games: res
                // };
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key: category
                    }
                })
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.class-body{
    @media (min-width: 768px){
        &::-webkit-scrollbar{
            height: 7px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(192,192,192,0.2);
        }
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(192,192,192,0.1);
        }
    }
    margin: 54px 0 41px;
    width: 100%;
    display: flex;
    overflow: auto;
    white-space: nowrap;
    .class-card{
        display: inline-block;
        padding: 10px 30px;
        box-sizing: border-box;
        color: #FFF;
        background: #292C37;
        border-radius: 22px;
        margin: 10px;
    }
    .s-card{
        border: 2px solid #F857A6;
    }
}
.list-title{
    margin-bottom: 8px;
    color: #FFF;
    height: 38px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 27px;
    color: #FFFFFF;
    line-height: 38px;
    text-align: left;
    font-style: normal;
}
.game-list{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 0 5px;
    box-sizing: border-box;
    grid-gap: 20px;

    .m-padding{
        padding: 10px 0;
    }
    .w-padding{
        padding: 19px 18px;
    }
    .game-card{
        background: #292C37;
        border-radius: 9px 9px 9px 9px;
        .card-body{
            display: flex;
            justify-content: space-between;
            .card-l{
                width: 135px;
                height: 135px;
                img{
                    width: 135px;
                    height: 135px;
                    border-radius: 9px;
                }
            }
            .card-r{
                width: 400px;
                color: #FFF;
                .r-title{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 18px;
                    color: #FFFFFF;
                    text-align: left;
                    font-style: normal;
                    margin-bottom: 11px;
                    -webkit-line-clamp: 1;
                }
                .r-syn{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: left;
                    font-style: normal;
                    -webkit-line-clamp: 4;
                }
            }
        }
        .m-body{
            color: #FFF;
            img{
                border-radius: 5px;
                display: block;
                width: 80px;
                height: 80px;
                margin: 0 auto;
            }
            .m-title{
                margin: 10px 0;
                text-align: center;
                -webkit-line-clamp: 1;
            }
            .m-syn{
                -webkit-line-clamp: 2;
            }
        }
        .card-play{
            width: 70px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            margin: 10px auto 0;
            background-color: #2757A5;
            color: #FFF;
            border-radius: 10px;
        }
    }
}
.line-clamp{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>