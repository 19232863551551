<template>
    <div>
        <div class="header">
            <div>
                <img src="@/assets/phone2/yuele.png" alt="" @click="goIndex">
            </div>
            <div class="header-body">
                <input placeholder="keywords" v-model="searchText" type="text">
                <div @click="search">search</div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'

export default {
    data() {
        return {
            searchText: ''
        }
    },
    mounted() {
        eventBus.$on('showfenlei', i => {
            this.showMenu = i
        })
    },
    methods: {
        search() {
            if (this.$route.path == '/selectGame') {
                eventBus.$emit('searchgame', {
                    id: 'search',
                    key: this.searchText
                })
            } else {
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'search',
                        key: this.searchText
                    }
                })
            }
        },
        goIndex() {
            if (this.$route.path == '/') {
                this.$router.go(0)
            } else {
                this.$router.push('/')
            }
        }
    },
}
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
    .header {
        width: 1200px;
        left: 50%;
        transform: translateX(-50%);
        height: 55px;
    }
}

@media (max-width: 768px) {
    .header {
        width: 100vw;
    }
}

.header {
    position: fixed;
    top: 0;
    background-color: #2757A5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;

    img {
        width: 41px;
        height: 41px;
    }

    .header-body {
        width: 260px;
        height: 55px;
        display: flex;
        align-items: center;

        input {
            &::placeholder {
                font-family: Inter, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #B1B8C8;
                line-height: 19px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }

            color: #FFF;
            display: block;
            padding-left: 8px;
            box-sizing: border-box;
            width: 180px;
            height: 36px;
            background: #3B3E4B;
            border-radius: 18px;
            border: none;
        }

        div {
            margin-left: 8px;
            width: 70px;
            height: 35px;
            line-height: 35px;
            background: #203E70;
            border-radius: 5px;
            border: 1px solid #5BA0FD;
            cursor: pointer;
            font-family: Inter, Inter;
            font-weight: 500;
            font-size: 16px;
            color: #F3F3F3;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
    }
}
</style>